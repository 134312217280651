@import "mixins/transition.pcss";

#login {
  width: 336px;
}

.login {
  @apply bg-dark-50;
  position: relative;

  &::before {
    content: '';
    filter: brightness(-100%);
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.4;
  }

  & a {
    color: #a2aab4 !important;
  }

  & h1 {
    display: none;
  }

  & svg {
    display: block;
    width: 100%;
    height: 100%;
    max-width: 14rem;
    margin: 2rem auto 4rem;
  }

  & .button-primary {
    @apply bg-green border-green text-white;
    @apply --transition-fast;
    box-shadow: 1px 1px 1px theme('colors.green.default');
    text-shadow: none;

    &:active,
    &:focus,
    &:hover {
    }
  }
}
